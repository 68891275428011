import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../../components/layout';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Modal from 'react-modal';

import * as styles from './product.module.scss';

const ProductDetail = ({ pageContext }: any) => {
  Modal.setAppElement('#___gatsby');
  const [modalIsOpen, setModalOpen] = React.useState(false);
  const [imageToshow, setImageToShow] = React.useState<IGatsbyImageData>();

  let product: IContentfulProduct = pageContext.product;
  let image: IGatsbyImageData | undefined = getImage(
    product.coverImage.gatsbyImageData
  );
  let imageFullUrl = product.coverImage.file.url;
  let gallery = product.gallery?.flatMap((item) => getImage(item));
  let bluePrint: IGatsbyImageData | undefined = getImage(
    product?.blueprints?.gatsbyImageData
  );

  const handleImageClick = (imageData: IGatsbyImageData) => {
    setImageToShow(imageData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const modalCustomStyles = {
    overlay: {
      zIndex: '1000',
    },
    content: {
      border: '1px solid rgba(0,0,0,0.1)',
      padding: '0',
      height: 'auto',
      maxHeight: '90vh',
      width: '90vh',
      maxWidth: '90vw',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Layout>
      <div
        className={`${styles.productHeaderWrapper} container-fluid px-0 pt-1 pb-5`}
      >
        <div>
          {image && (
            <img src={imageFullUrl} alt={product.name + ' cover image'} />
          )}
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="py-4">{product.name}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div>Material: {product.material}</div>
            <div>Finish: {product.finish}</div>
            <div>Dimensions: {product.dimensions}</div>
          </div>
          <div className="col-12 col-md-8 pt-4 pt-md-0">
            {product.description &&
              documentToReactComponents(JSON.parse(product.description.raw))}
          </div>
        </div>
        <div className="row pt-5">
          {gallery?.map(
            (galleryImage, index) =>
              galleryImage && (
                <div
                  key={index}
                  className="col-12 col-md-4 mb-4 gx-5 gy-4"
                  onClick={() => handleImageClick(galleryImage)}
                  role="button"
                >
                  <GatsbyImage
                    image={galleryImage}
                    objectFit="cover"
                    alt="{product.name}"
                  />
                </div>
              )
          )}
        </div>
        <div className="row mt-5 pt-5 justify-content-between">
          <div className="col-12 col-md-2 d-flex flex-column justify-content-end">
            <div className="fst-italic">
              {product.blueprintsDescription &&
                documentToReactComponents(
                  JSON.parse(product.blueprintsDescription?.raw)
                )}
            </div>
          </div>
          <div className="col-12 col-md-4 justify-content-center">
            {bluePrint && (
              <GatsbyImage
                image={bluePrint}
                objectFit="cover"
                alt="{product.name}"
              />
            )}
          </div>
          <div className="col-12 col-md-2"></div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        style={modalCustomStyles}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        {imageToshow && (
          <GatsbyImage
            image={imageToshow}
            objectFit="cover"
            alt="{product.name}"
          />
        )}
      </Modal>
    </Layout>
  );
};

export default ProductDetail;
